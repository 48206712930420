import * as React from "react";
import API from "./api";
import Header from "./Header";
import Footer from "./Footer";

export default class SupportPage extends React.Component {
    state = {
        contact: {},
        ready: false
    }

    //'name', 'label', 'icon', 'date_publish'
    componentDidMount() {
        let self = this;
        API.get(`/company_contact`).then(function (response) {
            const contact = response.data[0];
            self.setState({contact, ready:true});
        }).catch(function (error) {
            console.log(error);
        }).finally(function () {
        });
    }

    render() {

        return (
            <div className="home blog">
                <Header/>

                <main className="wrapper">
                    <div className="inner">
                        <section className="appbox">
                            <div className="boxtop">
                                <h2>产品支持</h2>
                            </div>
                            <div className="applist">
                                <ul>
                                    <li key='qq' >QQ:{this.state.contact.qq}</li>
                                    <li key='addr' >地址:{this.state.contact.addr}</li>
                                    <li key='phone' >电话:{this.state.contact.phone}</li>
                                    <li key='email' >邮件:{this.state.contact.email}</li>
                                </ul>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}