import './App.css';
import * as React from "react";

import { Routes, Route, Link } from "react-router-dom";
import MainPage from "./MainPage";
import SupportPage from "./SupportPage";
import PrivacyPage from "./PrivacyPage";
import ItemPage from "./ItemPage";
import CommentPage from "./CommentPage";
import MiniItemPage from "./MiniItemPage";
import ServicePage from "./ServicePage";
import GoldenGlobePageComponent from './GoldenGlobePage';
export default function App() {
  return (
        <Routes>
          <Route path="/" element={<GoldenGlobePageComponent />}/>
            <Route path="support" element={<SupportPage />} />
            <Route path="privacy/:name?" element={<PrivacyPage />} />
            <Route path="service_agreement/:name?" element={<ServicePage />} />
            <Route path="comment" element={<CommentPage />} />
            <Route path="download/:url_path" element={<ItemPage />} />
            <Route path=":url_path/download" element={<MiniItemPage />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
  );
}


function NoMatch() {
  return (
      <div>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </div>
  );
}