import * as React from "react";
import API from "./api";
import Header from "./Header";
import Footer from "./Footer";
import './CommentPage.css'

export default class CommentPage extends React.Component {
    state = {
        contact: {},
        ready: false
    }

    //'name', 'label', 'icon', 'date_publish'
    componentDidMount() {
        let self = this;
        API.get(`/company_contact`).then(function (response) {
            const contact = response.data[0];
            self.setState({contact, ready:true});
        }).catch(function (error) {
            console.log(error);
        }).finally(function () {
        });
    }
    handleSubmit()
    {

    }
    render() {

        return (
            <div className="home blog">
                <Header/>

                <main className="wrapper">
                    <div className="inner">
                        <section className="appbox">
                            <div className="boxtop">
                                <h2>产品支持</h2>
                            </div>
                            <div className="applist">
                                <div className="Content-Main">
                                    <div className="Content-Main1">
                                        <h1>用户反馈</h1>
                                    </div>
                                    <form action="" method="post" className="form-report">
                                        <label>
                                            <span>您的联系方式:</span>
                                            <select name="select2" className="select2">
                                                <option value="Email">邮件</option>
                                                <option value="Phone">电话</option>
                                                <option value="QQ">QQ</option>
                                            </select>
                                            <input type="text" placeholder=""/>
                                        </label>
                                        <label>
                                            <span>内容:</span>
                                            <textarea id="mesaage" name="message" placeholder="请给我们反馈"></textarea>
                                        </label>
                                        <label>
                                            <span>分类:</span>
                                            <select name="select1" className="select1">
                                                <option value="bug">Bug反馈</option>
                                                <option value="general">一般问题</option>
                                            </select>
                                        </label>
                                        <label>
                                            <input type="button" className="button" value="Send" onClick={this.handleSubmit}/>
                                        </label>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}