import {Link} from "react-router-dom";
import * as React from "react";
import "./css/style.css"

export default function Header() {
    return (
        <header className="header">
            <div className="inner">
                <h1 className="logo"><Link
                    to="/"
                    >"九极星AR官网"</Link></h1>
            </div>
        </header>
);
}