import * as React from "react";
import API from "./api";
import Header from "./Header";
import Footer from "./Footer";
import {useParams} from "react-router";
class PrivacyPage extends React.Component {
    state = {
        privacy: '',
        ready: false
    }
    componentDidMount() {
        let {name} = this.props.params;
        let self = this;
        if (!name) {
            API.get(`/privacy/1`).then(function (response) {

                const privacy = response.data;
                console.log(privacy);
                self.setState({privacy, ready: true});
            }).catch(function (error) {
                console.log(error);
                return <h1>Error</h1>
            });
        }
        else
        {
        API.get(`/privacy?name=${name}`).then(function (response) {

            const privacy = response.data[0];
            console.log(privacy);
            self.setState({privacy, ready: true});
        }).catch(function (error) {
            console.log(error);
            return <h1>Error</h1>
        });
        }
    }
    //'name', 'label', 'icon', 'date_publish'
    render() {
        if(!this.state.ready)
            return <div/>
        return (
            <div className="occupy">
                <Header/>
                <main className="wrapper">
                    <div className="inner">
                        <section className="appbox">
                            <div className="boxtop">
                                <h2>隐私条款</h2>
                            </div>
                                <article className="apppage">
                    <div dangerouslySetInnerHTML={{__html:this.state.privacy.privacy}} />
                                </article>

                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()}/>;
}

export default withParams(PrivacyPage);