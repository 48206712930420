import * as React from "react";
import API from "./api";
import "./css/style.css"
import Header from "./Header";
import Footer from "./Footer";
import {Link} from "react-router-dom";

export default class MainPage extends React.Component {
    state = {
        apps: [],
        ready: false
    }

    //'name', 'label', 'icon', 'date_publish'
    componentDidMount() {
        let self = this;
        API.get(`/app_public_info`).then(function (response) {
            const apps = response.data;
            self.setState({apps, ready:true});
        }).catch(function (error) {
            console.log(error);
        }).finally(function () {
        });
    }
    truncate(str){
        if(str.length === 0)
            return '暂无';
        return str.length > 48 ? str.substring(0, 45) + "..." : str;
    }
    render() {
        return (
            <div className="occupy">
            <Header/>
            <div className="bar">
                <div className="inner">
                    <nav className="nav">
                        <ul className="menus">
                            <li id="menu-item-8"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-8">
                                <Link to="/" aria-current="page">首页</Link></li>

                            <li id="menu-item-10"
                                className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-has-children menu-item-10">
                                <Link to="/comment">留言反馈</Link></li>
                        </ul>
                    </nav>
                    <div className="clear"></div>
                </div>
            </div>
            <main className="wrapper">
                <div className="inner">
                    <section className="appbox">
                        <div className="boxtop">
                            <h2>应用列表</h2>
                        </div>
                        <div className="applist">
                            <ul>
                                {
                                    this.state.apps.map(app =>  <li key={app.url_path} >
                                        <div className="box">
                                            <figure className="icon">
                                                <a href={'/download/' + app.url_path} target="_blank" rel="noreferrer" title={app.name}> <img
                                                    src={app.icon} alt={app.name}/> </a>
                                            </figure>
                                            <div className="info">
                                                <h3><a href={'/download/' + app.url_path} target="_blank" rel="noreferrer" title={app.name}>{app.name}</a>
                                                </h3>
                                                <div className="meta">
                                                    <span>发布日期：{app.date_publish}</span>
                                                </div>
                                                <div className="meta">
                                                    <span>版本：{app.latest_version}</span>
                                                </div>
                                            </div>
                                            <div className="clear"></div>
                                            <div className="intro">
                                                <em>简介：</em>{this.truncate(app.brief_intro)}
                                            </div>
                                        </div>
                                    </li>
                                        )
                                }
                            </ul>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
            </div>
        )
    }
}