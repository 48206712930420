import * as React from "react";
import API from "./api";
import Header from "./Header";
import Footer from "./Footer";
import {useParams} from "react-router";
class ServicePage extends React.Component {
    state = {
        privacy: '',
        ready: false
    }
    componentDidMount() {
        let {name} = this.props.params;
        let self = this;
        if (!name) {
            API.get(`/service_agreement/1`).then(function (response) {
                const sa = response.data;
                self.setState({sa, ready: true});
            }).catch(function (error) {
                console.log(error);
                return <h1>Error</h1>
            });
        }
        else{
            API.get(`/service_agreement?name=${name}`).then(function (response) {
                const sa = response.data[0];
                self.setState({sa, ready: true});
            }).catch(function (error) {
                console.log(error);
                return <h1>Error</h1>
            });
        }

    }
    //'name', 'label', 'icon', 'date_publish'
    render() {
        if(!this.state.ready)
            return <div/>
        return (
            <div className="occupy">
                <Header/>
                <main className="wrapper">
                    <div className="inner">
                        <section className="appbox">
                            <div className="boxtop">
                                <h2>用户服务协议</h2>
                            </div>
                                <article className="apppage">
                                    <div dangerouslySetInnerHTML={{__html:this.state.sa.content}} />
                                </article>

                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()}/>;
}

export default withParams(ServicePage);