import {Link} from "react-router-dom";
import * as React from "react";
import "./css/style.css"

export default function Footer() {
    return (
        <footer>
            <div className="copyright">
                <div className="inner">
                    <p>天津市捌壹陆商贸有限公司 版权所有 ©2018-2023  </p>
                    <p> <Link to="/" target="_blank" rel="noreferrer"
                          >ICP备案号：津ICP备19000494号</Link> </p>
                </div>
            </div>
            <div className="bg"></div>
        </footer>
    );
}
