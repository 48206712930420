import * as React from "react";
import API from "./api";
import Header from "./Header";
import './css/style.css'
import {useParams} from "react-router";
import Footer from "./Footer";

class ItemPageComponent extends React.Component {
    state = {
        appData: {},
        ready: false
    }

    componentDidMount() {
        let {url_path} = this.props.params;
        let self = this;
        API.get(`/app_public_info?url_path=${url_path}`).then(function (response) {
            const appData = response.data[0];
            console.log(appData);
            self.setState({appData, ready: true});
        }).catch(function (error) {
            console.log(error);
            return <h1>Error</h1>
        });

    }

    render() {
        if (!this.state.ready)
            return <div/>
        return (
            <div className="occupy">
                <Header/>
                <main className="wrapper">
                    <div className="inner">
                        <section className="main appbox">
                            <div className="boxtop">
                                <h2>应用下载</h2>
                            </div>
                            <article className="apppage">
                                <div className="appinfo">
                                    <div className="left">
                                        <figure className="icon">
                                            <img src={this.state.appData.icon} alt={this.state.appData.name}/>
                                        </figure>
                                    </div>
                                    <div className="middle">
                                        <h1 className="title">{this.state.appData.name}</h1>
                                        <div className="meta">
                                            <span><i>分类：</i><a href="https://www.arapp.online"
                                                                 rel="category tag">AR应用</a></span>
                                            <span><i>语言：</i>简体中文</span>
                                            <span><i>热度：</i>501</span>
                                            <span><i>点评：</i>0</span>
                                        </div>
                                        <div className="date">
                                            <em>发布：</em>
                                            <time
                                                dateTime={this.state.appData.date_publish}>{this.state.appData.date_publish}</time>
                                        </div>
                                        <div className="apptags">
                                            <span>关键词：</span>
                                            <ul>
                                                <li><a href="https://www.arapp.online" rel="tag">AR应用</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="btn iphone">
                                            <div className="canvas">
                                                <div id="ios" className="qr"></div>
                                            </div>
                                            <a rel="noreferrer" href={this.state.appData.ios_download_url}
                                               title="直接下载"
                                               target="_blank">苹果下载</a>

                                        </div>
                                        <div className="btn android">
                                            <div className="canvas">
                                                <div id="android" className="qr"></div>
                                            </div>
                                            <a rel="noreferrer" href={this.state.appData.android_download_url}
                                               title="直接下载"
                                               target="_blank">安卓下载</a>

                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <section className="appscreen">
                                    <h2 className="apptitle">APP截图</h2>
                                    <img src={this.state.appData.image_list[0].image}
                                        className="load" alt=""/>

                                </section>
                                <section className="content">
                                    <h2 className="apptitle">应用介绍</h2>
                                    <div className="entry" id="maximg">
                                        {
                                            this.make_divs(this.state.appData.intro)
                                        }
                                        <p><br/></p>
                                    </div>
                                </section>

                            </article>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }

    make_divs(intro) {
        if (typeof intro === "undefined")
            return <div/>
        let paras = intro.split('\r\n');
        return paras.map((p, index) =>
            <p key={index}>{p}</p>
        );
    }

    make_carousel(l) {
        if (typeof l === 'undefined')
            return <div/>
        else {
            return l.map(
                img => (
                    <div key={img.image}>
                        <img src={img.image}
                             className="attachment-full size-full" alt="" loading="lazy"/>
                    </div>
                )
            )
        }
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()}/>;
}

export default withParams(ItemPageComponent);